import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './img/monkey.webp';

function App() {
  const [url, setUrl] = useState('');
  const [shortenedUrl, setShortenedUrl] = useState('');
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const baseWorkerUrl = 'https://scx.me';

  const isValidUrl = (url) => {
    if (url.includes('://scx.me')) {
      return false;
    }
  
    const urlRegex = new RegExp('^(https?://|ftp://)([a-zA-Z0-9+.-]*)(:[0-9]+)?(/[^ "]+)*(/[^ "]*[^/ "])?(\\?[^ #]*)?(#[^ ]*)?$', 'i');
    return urlRegex.test(url);
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsError(false);

    if (!isValidUrl(url)) {
      setIsError(true);
      setShortenedUrl('Error: Invalid URL format.');
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetch(baseWorkerUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ url }),
      });

      const data = await response.json();
      if (response.ok) {
        setShortenedUrl(`${baseWorkerUrl}${data.key}`);
      } else {
        setIsError(true);
        setShortenedUrl(`Error: ${data.message}`);
      }
    } catch (error) {
      setIsError(true);
      setShortenedUrl(`Error: ${error.toString()}`);
    }

    setIsLoading(false);
  };

  const handleCopy = () => {
    if (!isError) {
      navigator.clipboard.writeText(shortenedUrl);
      alert('URL copied to clipboard!');
    }
  };

  return (
    <div className="d-flex vh-100 justify-content-center align-items-center">
      <div className="card" style={{ width: '18rem' }}>
        <div className="card-body">
          <div className="text-center mb-4">
            <img src={logo} alt="Monkey Logo" style={{ maxWidth: '200px' }} /> {/* Adjust size as needed */}
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="Enter URL to shorten"
              />
            </div>
            <button type="submit" className={`btn btn-primary w-100 ${isLoading ? 'disabled' : ''}`}>
              {isLoading ? 'Shortening...' : 'Shorten URL'}
            </button>
          </form>
          {shortenedUrl && (
            <div className="mt-3 text-center">
              <p className={isError ? "text-danger" : "text-success"}>
                {shortenedUrl}
              </p>
              {!isError && (
                <button onClick={handleCopy} className="btn btn-success w-100">
                  Copy URL
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
